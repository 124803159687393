import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { CountryCodes } from 'src/app/core/enums/country-codes.enum';

import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { Product } from 'src/app/core/models/product.model';
import { UserInfo } from 'src/app/core/models/user-info.model';

import { CartService } from 'src/app/core/services/cart.service';

import * as CartActions from 'src/app/core/state/actions/cart.actions';

import { ModalMobileFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-mobile-frozen-delivery-date.service';
import { IsMobileService } from 'src/app/core/services/is-mobile/is-mobile.service';
import { ModalDesktopFrozenDeliveryDateService } from 'src/app/shared/components/select-frozen-delivery-date/services/modal-desktop-frozen-delivery-date.service';

@Component({
  selector: 'app-ka-notification-cart',
  templateUrl: './ka-notification-cart.component.html',
  styleUrls: ['./ka-notification-cart.component.scss'],
})
export class KaNotificationCartComponent {
  private subscriptions = new Subscription();
  private quantityItems: number = 0;
  readonly CountryCodes = CountryCodes;
  selectedClient: Client;
  user: UserInfo;
  cart: Cart;
  get isMobile(): boolean {
    return this._isMobile.isMobile;
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{ user: UserInfo; client: Client; cart: Cart }>,
    private cartService: CartService,
    private _modalMobileFrozenDeliveryDateService: ModalMobileFrozenDeliveryDateService,
    private _modalDesktopFrozenDeliveryDateService: ModalDesktopFrozenDeliveryDateService,
    private _isMobile: IsMobileService,
  ) {
    this.subscriptions.add(
      this.store.select('user').subscribe((user) => (this.user = user)),
    );
    this.subscriptions.add(
      this.store.select('client').subscribe((client) => {
        this.selectedClient = client;
      }),
    );
    this.subscriptions.add(
      this.store.select('cart').subscribe((cart) => (this.cart = cart)),
    );
    
  }

  get hasDraftItems(): boolean {
    return this.selectedClient?.data?.drafts[0]?.items.length > 0;
  }

  get hasvisitDates(): boolean {
    return this.selectedClient?.data?.visitDates.length > 0;
  }

  goPortfolio(isDraft: boolean): void {
    const myOrdersRoute = this.router.url.includes('mcc')
      ? '/mcc/2'
      : '/main/nuevo-pedido/2';
    this.router.navigate([myOrdersRoute], { queryParams: { draft: isDraft } });
  }

  goOrderDetail(): void {
    if (
      !!this.selectedClient.data.drafts?.length &&
      !this.cart.products?.length
    ) {

      const products: Product[] = this.getProductsFromClientDraft();
      if (!this.cart.products?.length) {
        // si algun producto del draft tiene deliveryfrozen abrimos el modal para que el usuario seleccione la fecha
        const frozenProduct = products.find(
          (product) => product.deliveryType === 'deliveryfrozen',
        );
        if (frozenProduct) {
          this.deliveryFrozenActionsDraft(products);
        } else {
          this.store.dispatch(
            CartActions.upsertMultipleProducts({ products }),
          );
          this.cartService.updateDeliveryProducts();
        }
        
        const orderRouteB2b = this._isMobile.isMobile ? '/main/nuevo-pedido/3' : '/main/nuevo-pedido/4';

        const myOrdersRoute = this.router.url.includes('mcc')
          ? '/mcc/4'
          : orderRouteB2b;

        this.router.navigate([myOrdersRoute]);
      }

    }
  }

  getProductsFromClientDraft(): Product[] {
    return this.selectedClient.data.drafts[0].items.map((item) => {
      return {
        ...item,
        quantitySelected: item.quantity,
        subunitSelected: item.erpMeasureUnitId,
      };
    });
  }

  deliveryFrozenActionsDraft(cartProducts: Product[]) {
    if (this.isMobile) {
      this._modalMobileFrozenDeliveryDateService.openModal();
      this._modalMobileFrozenDeliveryDateService.isCloseModal$
        .pipe(take(1))
        .subscribe({
          next: (isClose) => {
            if (isClose) {
              this.handleFrozenModalCloseActions(cartProducts);
            }
          },
        });
    } else {
      this._modalDesktopFrozenDeliveryDateService
        .openModal()
        .result.then(() => {
          this.handleFrozenModalCloseActions(cartProducts);
        });
    }
  }

  private handleFrozenModalCloseActions(cartProducts: Product[]): void {
    this.store.dispatch(
      CartActions.upsertMultipleProducts({ products: cartProducts }),
    );
    this.cartService.updateDeliveryProducts();
  }

  removeQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
  }

}
