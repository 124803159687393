import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { EndpointsCodes } from 'src/app/core/enums/endpoints-codes.enum';
import { BERespModel } from 'src/app/core/models/backend/BE-response.model';
import { Cart } from 'src/app/core/models/cart.model';
import { Client } from 'src/app/core/models/client.model';
import { ApiService } from 'src/app/core/services/api.service';
import { ProductsCalcs } from 'src/app/core/utils/products-calcs';
import { FEATURES } from 'src/environments/utils/env.model';
import { env } from 'src/app/app.component';
import { UserInfo } from 'src/app/core/models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class NewOrderService {
  stepNumber$ = new Subject<number>();
  client: Client;
  cart: Cart;
  user: UserInfo;
  orderId: number;
  readonly EndpointsCodes = EndpointsCodes;

  constructor(
    private apiSrv: ApiService,
    private store: Store<{ client: Client; cart: Cart; user: UserInfo }>,
    private gtmService: GoogleTagManagerService,
  ) {
    this.store.select('client').subscribe((client) => (this.client = client));
    this.store.select('cart').subscribe((cart) => (this.cart = cart));
    this.store.select('user').subscribe((user) => (this.user = user));
  }

  createOrder(): Observable<BERespModel> {
    const isMultiplePaymentMethodAvailable = env.isFeatureAvailable(
      FEATURES.PAYMENT_METHODS_BY_CATEGORY,
    );
    const showLoadingScreen = env.isFeatureAvailable(
      FEATURES.LOADING_SCREEN,
    );
    if (
      isMultiplePaymentMethodAvailable &&
      this.cart.multiplePaymentMethodsBySegment?.length > 0
    ) {
      return new Observable((obs) => {
        this.apiSrv
          .post(
            `clients/${this.client.clientId}/order/${this.cart.orderId}`,
            EndpointsCodes.POST_ORDER,
            this.formatMultiplePaymentMethod(),
            { showLoadingScreen },
          )
          .subscribe(
            (res) => {
              this.gtmService.pushTag({
                event: 'createOrderConfirm',
                order: this.parseGTMdata(this.cart),
              });
              obs.next(res);
            },
            (err) => obs.error(err),
            () => obs.complete(),
          );
      });
    }

    return new Observable((obs) => {
      // TODO refactor this to also work when there are multiple paymentMethods
      const externalOrderParams =
        this.user.origin === 'KOBOSS'
          ? {
              isExternal: true,
              ExternalAuth: {
                authValue: this.user.authValue,
                authType: this.user.authType,
              },
            }
          : {};
      this.apiSrv
        .post(
          `clients/${this.client.clientId}/order/${this.cart.orderId}`,
          EndpointsCodes.POST_ORDER,
          {
            ...externalOrderParams,
            paymentMethod: this.cart.paymentMethod.erpPaymentMethodId,
            purchaseOrderNumber: this.cart.purchaseOrderNumber,
          },
          { showLoadingScreen },
        )
        .subscribe(
          (res) => {
            this.gtmService.pushTag({ event: 'createOrderConfirm', order: this.parseGTMdata(this.cart) });
            obs.next(res);
          },
          (err) => obs.error(err),
          () => obs.complete()
        );
    });
  }

  private parseGTMdata(cart: Cart): any {
    const products = cart.discountProducts.map((prod) => {
      return {
        productId: prod.productId,
        erpProductId: parseInt(prod.erpProductId) || '',
        name: prod.name,
        quantity: prod.quantity,
        category: prod.productGroup?.category,
        finalPrice: prod.price.finalPrice,
        listPrice: ProductsCalcs.getItemFullListPrice(prod),
      };
    });

    let parsedData = {
      orderId: cart.orderId,
      paymentMethod: cart.paymentMethod,
      date: moment().format('DD/MM/YYYY hh:mm'),
      deliveryDate: moment(new Date(cart.visitDate).toISOString().replace('Z', '')).format('DD/MM/YYYY'),
      totalPrice: cart.totalPrice,
      products,
    };
    if (cart.hasDeliveryFrozenProducts) {
      parsedData['frozenDeliveryDate'] = moment(new Date(cart.frozenVisitDate).toISOString().replace('Z', '')).format('DD/MM/YYYY');
    }
    return parsedData;
  }

  private formatMultiplePaymentMethod() {
    const paymentMethodFormated = this.cart.multiplePaymentMethodsBySegment.map(
      (paymentMethodBySegment) => {
        return {
          segmentId: paymentMethodBySegment.segmentId,
          paymentMethod:
            paymentMethodBySegment.paymentMethod.erpPaymentMethodId,
        };
      },
    );
    return { paymentMethod: paymentMethodFormated };
  }
}
