import { createAction, props } from '@ngrx/store';
import { Discount } from '../../models/discount.model';
import { Product } from '../../models/product.model';
import { BERespErrorModel } from '../../models/backend/BE-response-error.model';
import { ProductFilter } from '../../models/product-filter.model';
import { PortfolioFilters } from '../../models/portfolio-filters.model';

export const loadPortfolio = createAction(
  '[Portfolio] loadPortfolio',
  props<{ offset: number }>(),
);

export const loadPortfolioSuccess = createAction(
  '[Portfolio] loadPortfolioSuccess',
  props<{
    products: (Product | Discount)[];
    page: number;
    totalProducts: number;
  }>(),
);

export const loadPortfolioError = createAction(
  '[Portfolio] loadPortfolioError',
  props<{ error: BERespErrorModel }>(),
);

export const loadPortfolioFilter = createAction(
  '[Portfolio] loadPortfolioFilter',
  props<{ productFilter:ProductFilter| null }>(),
);

export const loadPortfolioFilterSuccess = createAction(
  '[Portfolio] loadProductFilterSuccess',
  props<{ portfolioFilters: PortfolioFilters }>(),
);

export const loadPortfolioFilterError = createAction(
  '[Portfolio] loadProductFilterError',
);
